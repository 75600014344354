import {gql} from "@apollo/client";

export const getProductsQuery = gql`
  query getProducts($voucherCode: String, $buildingCode: String, $showHidden: Boolean, $selectedProductIds: [String]) {
    classMethods {
      Product {
        getProducts(voucherCode: $voucherCode, buildingCode: $buildingCode, showHidden: $showHidden, selectedProductIds: $selectedProductIds) {
          id
          name
          type
          description
          active
          value
          originalValue
          rateProfile {
            name
            description
            profile
          }
          deviceLimit
          period
          once
          selectEventsActivate
          addonType
          hidden
          onlyNewConnection
          voucherName
          voucherUses
        }
      }
    }
  }
`;

export function getProductsResult({ data }) {
  return data?.classMethods?.Product?.getProducts || [];
}
